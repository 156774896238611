<template>
    <div>
      <Banner />
      <NavBar>
        <el-breadcrumb-item style="line-height: 41px;font-size: 12px;">
          <router-link to="/contact-us">联系我们</router-link>
        </el-breadcrumb-item>
      </NavBar>
      <div id="content">
        <div class="leftbox">
          <h2>
            深圳市晶准通信技术有限公司
          </h2>
          <div class="tag" v-for="(item,index) in tagData" :key="index">
            <a>
              <img :src="item.tagImg" />
            </a>
            <div style="padding-left:15px">
              <span class="tagName">{{item.tagName}}:</span>
              <span class="tagContent">{{item.tagContent}}</span>
            </div>
          </div>
          <div class="tag">
            <a style="
              width:45px;height:45px;background:rgb(244,243,239);border-radius: 50%;
              text-align: center;line-height: 45px;
            ">
              <i class="el-icon-phone" style="color:rgb(192, 191, 189)"></i>
            </a>
            <div style="padding-left:15px">
              <span class="tagName">联系方式:</span>
              <span class="tagContent">19129549640</span>
            </div>
          </div>
        </div>
        <div id="rightbox">
            <Mapview></Mapview>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Banner from '../components/common/Banner'
  import NavBar from '../components/common/NavBar'
  import Mapview from '../amap/Mapview'
  export default {
    name:"ContactUs",
    components: {
      Banner,
      NavBar,
      Mapview,
    },
    data(){
      return{
        tagData:[
          {
            tagImg:'https://pic.imgdb.cn/item/63ae6c7708b6830163eca2ad.png',
            tagName:'公司地址',
            tagContent:'深圳市深南大道10128号南山软件园东塔807-808室',
          },
          {
            tagImg:'https://pic.imgdb.cn/item/63ae6c7708b6830163eca2d1.png',
            tagName:'公司网址',
            tagContent:'www.rfarray.com',
          },
          {
            tagImg:'https://pic.imgdb.cn/item/63ae6c7708b6830163eca29b.png',
            tagName:'公司邮箱',
            tagContent:'jingzhun@rfarray.com',
          }
        ]
      }
    }
  }
  </script>
    
  <style scoped>
    #content{
      display: flex;
      max-width: 1200px;
      margin: auto;
      justify-content: space-around;
    }
    .leftbox{
      padding-left: 10px;
      width: 38%;
    }
    .leftbox > h2{
      padding: 40px 12px 20px;
    }
    .tag{
      display: flex;
      padding-bottom: 30px;
    }
    .tag a{
      display: inline-block;
      max-width: 45px;
      max-height: 45px;
    }
    .tag img{
      width: 100%;
      height: 100%;
    }

    .tagName{
      display: block;
      color: rgb(127, 127, 127);
      font-family: 'Microsoft YaHei';
      font-size: 14px;
      line-height: 150%;
    }
    .tagContent{
      font-size: 16px;
      line-height: 150%;
      font-family: 'Microsoft YaHei';
    }
    #rightbox{
      width: 50%;
    }
    @media (max-width:640px) {
      #content{
        display: block;
      }
      .leftbox{
        width: 100%;
        padding-left: 10%;
      }
      #rightbox{
        width: 80%;
        height: 400px;
        margin: auto;
      }
    }
  </style>